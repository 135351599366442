.header-players{
    display: flex;
    flex-direction: row;
    padding: 10px;
    padding: 10px;
    border-bottom: 1px solid #d0d5dd;
    gap: 20px;
    background-color: white;
}

.content-page{
    background-color: #F2F4F7;
}

.football-fields{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.space-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.filter{
    cursor: pointer;
}