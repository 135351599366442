.filter{
    position: relative;
}

.modal-filters{
    position: absolute;
    width: 200px;
    right: 20px;
    background-color: white;
    box-shadow: 0px 2px 5px 0px #e5dddd;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
}

fieldset{
    margin-top: 5px;
    border: 1px solid var(--gray-300);
    border-radius: 5px;
    margin-bottom: 5px;
}

legend{
    font-family: 'Inter';
    font-weight: 500;
    font-size: 12px;
    color: var(--gray-900);
}

label{
    font-weight: 500;
}

.football-fields{
    margin-top: 10px;
}