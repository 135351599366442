.card{
    display: flex;
    flex-direction: column;
    gap: 5px;

    .player{
        width: 20px;
        height: 20px;
        border: 1px solid black;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
    }
}

.flex-row{
    display: flex;
    flex-direction: row;
}

.between{
    justify-content: space-between
}
