.empty-stats-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 200px 0px;

    .text-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}