@mixin scrollbar {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: var(--scrollbar-size);
    height: var(--scrollbar-size);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--scrollbar-bg-color);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-bg-color);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-thumb-bg-color-hover);
  }
}

@mixin scrollbar-color {
  @include scrollbar();

  &::-webkit-scrollbar-track {
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-color-dark);
  }
}
