.modal-container {
  .subtitle {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 22px;
  }
  .buttons-section {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
    box-sizing: border-box;
    border-top: 1px solid var(--gray-100-min);
    height: 100px;
    width: 100%;
    background-color: var(--primary-color-25);
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.05);
    .button-container:last-child {
      margin-right: 25px;
    }
  }
  .modal-content {
    padding-bottom: 160px;
  }
  .upload-file-container {
    display: flex;
    justify-content: space-between;
  }
  .upload-file {
    .label {
      font-size: 14px;
      font-weight: 500;
      color: var(--gray-700);
      margin-bottom: 15px;
    }
  }
}
