.route-not-found {

    display: flex;
    align-items: center;
    gap: var(--interspace);
    width: 100%;
    height: 100vh;
    overflow: auto;
    justify-content: center;

    &-start, &-end {

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--interspace);
        box-sizing: border-box;
    }

    &-end {

        display: flex;
        flex-direction: column;
    }

    &-404 {

        background-image: url(../../../public/images/404-error.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: clamp( 100px, 100%, 515px );
        aspect-ratio: 1/1;
        margin: auto;

        @media ( max-width: 760px ) {

            width: clamp( 100px, 100%, 280px );
            height: 164px;
            aspect-ratio: unset;
        }
    }

    &-text-container {

        width: clamp( 280px, 100vw, 450px );
        margin: auto;
        display: flex;
        flex-direction: column;

        &-body {

            display: flex;
            flex-direction: column;
            gap: var(--interspace);
        }
    }

    @media ( max-width: 760px ) {

        flex-direction: column-reverse;
        margin: auto;
    }

    @media ( max-height: 580px ) {

        justify-content: flex-end;
    }
}