.secondary-navbar {

    min-width: var(--secondary-navbar-width);
    max-width: var(--secondary-navbar-width);
    background-color: var(--light-color);
    display: flex;
    flex-direction: column;
    padding: 15px;
    box-sizing: border-box;
    border-right: 1px solid #d0d5dd;
    gap: 5px;

    &-start {

        display: flex;
        flex-direction: column;
        gap: var(--interspace-s);
    }

    &-item {

        cursor: pointer;
        min-height: calc( var(--global-navbar-width) / 2);
        max-height: calc( var(--global-navbar-width) / 2);
        display: flex;
        align-items: center;
        padding-right: var(--interspace-xs);
        padding-left: calc( var(--interspace-xs) * 2 );
        border-radius: 5px;
        position: relative;
        z-index: 0;

        img, i {

            border-radius: 50%;
            width: 20px;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--primary-color-500);
        }

        span {

            font-size: 13px;
            font-weight: 500;

            &::before {

                position: absolute;
                left: var(--interspace-xs);
                content: '';
                width: 3px;
                background: var(--primary-color-400);
                top: calc( var(--interspace-xs) / 2 );
                bottom: calc( var(--interspace-xs) / 2 );
                border-radius: 100vw;
                transition: background-color 200ms, transform 200ms;
                transform: scale(0.85);
                opacity: 0;
            }
        }

        &::before {
            
            content: '';
            position: absolute;
            inset: 0;
            border-radius: 5px;
            background-color: #e6eef2b8;
            opacity: 0;
            transition: opacity 200ms, background-color 200ms, transform 200ms;
            z-index: -1;
            transform: scale(0.85);
        }

        &::after {

            content: '';
            position: absolute;
            top: calc( -1 * var(--interspace-s) / 2 );
            bottom: calc( -1 * var(--interspace-s) / 2 );
            left: calc( -1 * var(--interspace ) );
            right: calc( -1 * var(--interspace ) );
            width: var(--secondary-navbar-width);
            background-color: transparent;
        }
        
        &:hover {
            
            > span {

                &::before {

                    transform: scale(0.95);
                }
            }

            &::before {
                opacity: 0.5;
                transform: scale(1);
            }
        }

        &.active {
            
            > i {

                color: var(--primary-color-900);
            }

            > span {

                &::before {

                    opacity: 0;
                    transform: scale(1);
                    background-color: var(--primary-color-500);
                }
            }

            &::before {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}