@mixin growing-circle-hover {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    z-index: 0;
    
    &::before {
        position: absolute;
        content: '';
        background-color: hsla(0deg, 0%, 0%, 0.1);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        z-index: -1;
        transform: scale(0.25);
        opacity: 0;
        transition: opacity 300ms, transform 300ms; 
    }

    &:hover {
        &::before {
            transform: scale(1);
            opacity: 1;
        }
    }
}