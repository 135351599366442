* {

    font-family: 'Inter';
}

::selection {

    background-color: var(--primary-color-900);
    color: white;
}

a:not([class*="button"]) {

    font-weight: 500;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: var(--interspace-xs);
    cursor: pointer;

    > i, span {

        color: var(--primary-color-700);
    }

    &:hover {

        color: var(--primary-color-800);

        > i {
            
            text-decoration: none;
        }

        > span {

            color: var(--primary-color-800);
            text-decoration: underline;
        }
    }
}