.global-navbar {
  min-width: var(--global-navbar-width);
  max-width: var(--global-navbar-width);
  background-color: var(--light-color);
  box-sizing: border-box;
  z-index: 100;
  background-color: #033350;
  border-right: 1px solid var(--primary-color-100);

  &-head {
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid transparent;
    box-sizing: border-box;
  }

  &-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--interspace);
    padding-block: var(--interspace);
    align-items: center;
    height: calc(100vh - var(--header-height));
    box-sizing: border-box;

    &-start {
      display: flex;
      flex-direction: column;
      gap: var(--interspace);
      align-items: center;
    }
  }

  &-item {
    min-width: calc(var(--global-navbar-width) / 2);
    max-width: calc(var(--global-navbar-width) / 2);
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    position: relative;

    i {
      color: var(--primary-color-500);
      transition: color 300ms;
    }

    &.global-navbar-main-item {
      min-width: calc(var(--global-navbar-width) - 25px);
      max-width: calc(var(--global-navbar-width) - 25px);
      img {
        object-fit: contain;
        width: 85%;
        aspect-ratio: 1/1;
      }
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 5px;
      background-color: #034D78;
      opacity: 0;
      transition: opacity 200ms, background-color 200ms, transform 200ms;
      z-index: -1;
      transform: scale(0.85);
    }

    &::after {
      content: "";
      position: absolute;
      top: calc(-1 * var(--interspace) / 2);
      bottom: calc(-1 * var(--interspace) / 2);
      width: var(--global-navbar-width);
    }

    &:hover {
      &::before {
        opacity: 0.5;
        transform: scale(1);
      }
    }

    &.active {
      i {
        color: var(--primary-color-900);
      }

      &::before {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
