.table {
  .count {
    display: flex;
    justify-content: center;
    .count-tag {
      border-radius: 50%;
      display: flex;
      align-items: center;
      padding: 4px 7px;
      background-color: var(--primary-color-50);
      color: var(--primary-color-700);
      font-weight: 500;
    }
  }

  .status-container {
    border-radius: 35px;
    padding: 4px 13px;
    display: flex;
    justify-content: center;
    min-width: 90px;
    svg {
      font-size: 16px;
      opacity: 0.8;
    }
    span {
      font-size: 13px;
      font-weight: 500;
      margin-left: 5px;
    }
  }

  .dates-infos {
    display: flex;
    flex-direction: column;
    .date {
      color: var(--gray-500);
      font-size: 14px;
      margin-bottom: 4px;
      text-transform: capitalize;
    }
    .days {
      color: var(--gray-900);
      font-size: 14px;
    }
  }

  .users-avatars {
    .users-avatars-container {
      display: flex;
      align-items: center;
      .round {
        width: 32px;
        height: 32px;
        border-radius: 50px;
        &:nth-child(2) {
          transform: translateX(-10px);
          border: 2px solid white;
        }
      }
      img {
        &.no-avatar {
          border: 1px solid var(--gray-400) !important;
        }
      }
      .nb-users-left {
        background-color: var(--gray-100);
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateX(-20px);
        span {
          color: var(--gray-600);
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }
}
