.player-list{
    display: flex;
    flex-direction: row;
    gap: 10px;

    .player{
        width: 40px;
        height: 40px;
        border: 1px solid black;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .delete-player{
            position: absolute;
            top: -1px;
            right: -1px;
            color: var(--playerlist-icon-color);
            background-color: var(--playerlist-icon-bg);
            border-radius: 50%;
            height: 12px;
            width: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                cursor: pointer;
            }
            // transform: scale(1.2);
        }
    }
}
