.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}

.form {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    padding: 24px;
    gap: 24px;
}

h3 {
    margin-bottom: 6px;
}

.buttons {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    gap: 20px;
    position: sticky;
    bottom: 0px;
    width: 100%;
    padding: 24px 24px 24px;
    box-sizing: border-box;
    border-top: 1px solid var(--panel-footer-border);
}

img{
    border: 1px solid var(--panel-addplayer-img-border);
    width: 30px;
    height: 30px;
    border-radius: 30px;
}