.container {
    width: 100%;

}

.align-left {
    display: flex;
    justify-content: flex-start;
}

.align-right {
    display: flex;
    justify-content: flex-end;
}

