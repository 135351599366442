@import "../../style/mixins/_scrollbar.scss";

#root {
  position: absolute;
  inset: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;

  .site-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    main {
      display: flex;
      width: 100%;
      height: 100%;

      .page{
        width: 100%;
    
        .main-content {
          background-color: #F2F4F7;
          width: 100%;
          height: calc(100vh - 80px);
          max-height: calc(100vh - 80px);
          //padding: var(--interspace);
          //padding-top: 0px;
          overflow-y: scroll;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            /* Others navigator */
            display: none;
          }
        }
      }
    }
  }
}

.page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--header-height));
  background-color: rgb(230, 230, 230);

  nav {
    height: 100%;
  }

  .content {
    padding: var(--template-base-front-base-margin);
    background-color: var(--gray-100);
    height: 100%;
    overflow-y: auto;

    @include scrollbar;
  }
}
