@import "../../style/mixins/_growing-circle-hover.scss";

@-webkit-keyframes growing-bubble {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(200);
    opacity: 0;
  }
}

@keyframes growing-bubble {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(200);
    opacity: 0;
  }
}

*[data-bubble] {
  position: relative;
  overflow: hidden;
}

.bubble-effect {
  --bubble-click-radial-gradient: hsla(0deg, 0%, 0%, 0.1),
    hsla(0deg, 0%, 0%, 0.05);

  position: absolute;
  width: 1px;
  height: 1px;
  -webkit-animation: 1.5s cubic-bezier(0, 0, 0.2, 1) 0s growing-bubble;
  animation: 1.5s cubic-bezier(0, 0, 0.2, 1) 0s growing-bubble;
  transform: scale(1);
  opacity: 1;
  background-image: radial-gradient(var(--bubble-click-radial-gradient));
  z-index: 5;
  border-radius: 50%;
}

.button-container {
  position: relative;
  width: fit-content;
  width: -moz-fit-content;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  transition: all 300ms;
  width: fit-content;
  width: -moz-fit-content;
  min-height: 38px;
  padding: 0 20px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  min-width: 120px;
  position: relative;
  overflow: hidden;
  color: currentColor;
  text-decoration: none !important;
  box-shadow: 0px 2px 5px 0px hsla(0deg, 0%, 0%, 0.05);
  font-weight: 500;
  box-sizing: border-box;

  &:disabled{
    opacity: 0.4;
  }

  > i,
  span {
    transition: color 300ms;
  }

  > i {
    font-size: 18px;
  }

  > span {
    white-space: nowrap;
  }

  &:active {
    top: 1px;
  }

  &[data-type="primary"] {
    background-color: var(--primary-color-700);

    > i,
    > span {
      color: white;
    }

    &:hover {
      background-color: var(--primary-color-800);
    }
  }

  &[data-type="secondary"] {
    background-color: var(--light-color);
    border: 1px solid var(--primary-color-600);
    > i,
    > span {
      color: var(--primary-color-600);
    }

    &:hover {
      background-color: var(--gray-200);

      > i,
      > span {
        color: var(--primary-color-600);
      }
    }
  }

  &[data-type="tertiary"] {
    background-color: var(--light-color);

    > i,
    > span {
      color: var(--regular-color-dark);
    }

    &:hover {
      box-shadow: 0px 2px 5px 0px hsla(0deg, 0%, 0%, 0.1);

      > i,
      > span {
        color: var(--regular-color-dark-max);
      }
    }
  }

  &[data-type="danger"] {
    background-color: var(--danger-color-light);

    > i,
    > span {
      color: var(--danger-color-dark);
    }

    &:hover {
      background-color: var(--danger-color-light-min);

      > i,
      > span {
        color: var(--danger-color-dark-max);
      }
    }
  }

  &[data-type="warning"] {
    background-color: var(--warning-color-light);

    > i,
    > span {
      color: var(--warning-color-dark);
    }

    &:hover {
      background-color: var(--warning-color-light-min);

      > i,
      > span {
        color: var(--warning-color-dark-max);
      }
    }
  }

  &[data-type="image"] {
    width: 38px;
    height: 38px;
    min-width: unset;
    min-height: unset;
    border-radius: 50%;
    padding: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: unset;
  }

  &[data-size="small"] {
    width: 25px;
    height: 25px;
    min-width: unset;
    min-height: unset;
    box-sizing: border-box;
    border-radius: 50%;
    padding: 0;
    box-shadow: unset;

    > i {
      font-size: 14px;
    }

    @include growing-circle-hover;
  }
}
