header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: var(--header-height);
  max-height: var(--header-height);
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  background-color: var(--light-color);
  border-bottom: 1px solid #d0d5dd;

  nav {
    display: flex;
    gap: 15px;

    a {
      text-decoration: none;
      transition: color 0.2s ease-out;

      &:not(.selected):hover {
        color: #bfbfbf;
      }

      &.selected {
        cursor: unset;
        font-weight: bold;
      }
    }
  }
}

.header {
  &-start,
  &-end {
    display: flex;
    align-items: center;
    gap: var(--interspace-s);
  }

  &-breadcrumb {
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: var(--interspace-s);

    &-item {
      font-weight: 500;
      text-transform: uppercase;

      &:not(:first-child) {
        &::before {
          content: ">";
          margin-right: var(--interspace-s);
        }
      }
    }
  }
}
