.dropdown {
    --dropdown-width: 250px;
    --dropdown-opacity: 0;
    --dropdown-translate-y: 5px;
    --dropdown-item-base-padding-x: 10px;
    --dropdown-item-bullet-size: 5px;

    transition: opacity 300ms, transform 300ms;
    pointer-events: none;
    position: absolute;
    filter: drop-shadow( 2px 0px 15px hsla( 0deg, 0%, 0%, 0.15 ) );
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
    opacity: var(--dropdown-opacity);
    transform: translateY( var(--dropdown-translate-y) );
    min-width: var(--dropdown-width);
    max-width: var(--dropdown-width);
    display: flex;
    align-items: center;
    z-index: 1;

    * {
        pointer-events: none;
    }
    
    &::before {
        position: absolute;
        content: '';
        top: -6px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid var(--light-color);
    }

    &[data-is-dropdown-visible="true"] {
        --dropdown-opacity: 1;
        --dropdown-translate-y: 10px;

        pointer-events: auto;

        * {
            pointer-events: auto;
        }
    }
    

    &[data-dropdown-align="right"] {
        right: 0;
        align-items: end;

        &::before {
            transform: translateX(-100%);
        }
    }

    &[data-dropdown-align="left"] {
        left: 0;
        align-items: start;

        &::before {
            transform: translateX(100%);
        }
    }

    &[data-dropdown-align="center"] {
        left: calc( 50% - var(--dropdown-width) / 2 );
    }


    &-item {
        transition: color 150ms;
        padding: var(--dropdown-item-base-padding-x);
        background-color: var(--light-color);
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;

        &:hover {
            background-color: var(--gray-100);
        }

        &:not(:last-child) {
            border-bottom: 1px solid var(--gray-100);
        }
    }

    &[data-dropdown-items-type$="check"] &-item {
        padding-left: calc( var(--dropdown-item-base-padding-x) * 2 + var(--dropdown-item-bullet-size) );

        &::before {
            position: absolute;
            left: calc( var(--dropdown-item-base-padding-x) - var(--dropdown-item-bullet-size ) / 2 );
            content: '';
            background-color: var(--regular-color-dark-min);
            opacity: 0.75;
            width: var(--dropdown-item-bullet-size);
            height: var(--dropdown-item-bullet-size);
            border-radius: 50%;
            transition: left 300ms;
        }

        &::after {
            position: absolute;
            content: '';
            left: calc( var(--dropdown-item-base-padding-x) - var(--dropdown-item-bullet-size ) / 2 );
        }

        &[data-is-selected="true"] {
            &::before {
                background-color: var(--primary-color);
                opacity: 1;
                transform: rotate(-50deg);
                height: 5px;
                width: 3px;
                border-radius: 0;
            }

            &::after {
                background-color: var(--primary-color);
                opacity: 1;
                transform: rotate(-50deg) translateX(1px);
                height: 3px;
                width: 10px;
            }
        }
    }

    &[data-dropdown-items-type$="line-through"] &-item {
        &-text {
            position: relative;
            display: flex;
            align-items: center;

            &::before {
                position: absolute;
                content: '';
                background-color: var(--text-primary-color);
                width: 0px;
                transition: width 300ms;
                height: 1px;
            }
        }

        &[data-is-selected="true"] .dropdown-item-text {
            color: var(--text-primary-color-light-max);

            &::before {
                width: 100%!important;
            }
        }
    }
}