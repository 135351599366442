/* INDEX
 *
 * A. Palette de couleurs
 *   1. Primaire
 *   2. Secondaire
 *   3. Gris
 *   4. Porteur de signification
 *   5. Luminosité
 *   6. Texte
 * 
 * B. Scrollbars
 * C. Structure
 * D. Espacements
 *
 */

:root {

    /*
     * A. Palette de couleurs
     */
        // 1. Primaire
        --primary-color-25: #F2F6F8;
        --primary-color-50: #E6EEF2;
        --primary-color-100: #D9E5EB;
        --primary-color-200: #C0D2DD;
        --primary-color-300: #81A6BC;
        --primary-color-400: #427A9A;
        --primary-color-500: #1D5F86;
        --primary-color-600: #034D78;
        --primary-color-700: #034368;
        --primary-color-800: #033350;
        --primary-color-900: #022A42;

        // 2. Secondaire
        --secondary-color-dark-max: #1D2939;
        --secondary-color-dark: #101828;
        --secondary-color-dark-min: #1D2939;
        --secondary-color: #1D2939;
        --secondary-color-light-min: #344054;
        --secondary-color-light: #1D2939;
        --secondary-color-light-max: #1D2939;

        // 3. Gris
        --gray-25: #FCFCFD;
        --gray-50: #F9FAFB;
        --gray-100: #F2F4F7;
        --gray-200: #EAECF0;
        --gray-300: #D0D5DD;
        --gray-400: #98A2B3;
        --gray-500: #667085;
        --gray-600: #475467;
        --gray-700: #344054;
        --gray-800: #1D2939;
        --gray-900: #101828;

        // 4. Porteur de signification
        --danger-color-25: #FFFBFA;
        --danger-color-50: #FEF3F2;
        --danger-color-100: #FEE4E2;
        --danger-color-200: #FECDCA;
        --danger-color-300: #FDA29B;
        --danger-color-400: #F97066;
        --danger-color-500: #F04438;
        --danger-color-600: #D92D20;
        --danger-color-700: #B42318;
        --danger-color-800: #912018;
        --danger-color-900: #7A271A; 
        
        --warning-color-25: #FFFCF5;
        --warning-color-50: #FFFAEB;
        --warning-color-100: #FEF0C7;
        --warning-color-200: #FEDF89;
        --warning-color-300: #FEC84B;
        --warning-color-400: #FDB022;
        --warning-color-500: #F79009;
        --warning-color-600: #DC6803;
        --warning-color-700: #B54708;
        --warning-color-800: #93370D;
        --warning-color-900: #7A2E0E;

        --success-color-25: #F6FEF9;
        --success-color-50: #ECFDF3;
        --success-color-100: #D1FADF;
        --success-color-200: #A6F4C5;
        --success-color-300: #6CE9A6;
        --success-color-400: #32D583;
        --success-color-500: #12B76A;
        --success-color-600: #039855;
        --success-color-700: #027A48;
        --success-color-800: #05603A;
        --success-color-900: #054F31;  
        
        --info-color-25: #F5FAFF;
        --info-color-50: #EFF8FF;
        --info-color-100: #D1E9FF;
        --info-color-200: #B2DDFF;
        --info-color-300: #84ADFF;
        --info-color-400: #53B1FD;
        --info-color-500: #2E90FA;
        --info-color-600: #1570EF;
        --info-color-700: #175CD3;
        --info-color-800: #1849A9;
        --info-color-900: #1941B5;

        // 5. Luminosité
        --light-color: white;
        --dark-color: rgb(31, 31, 31);

        // 6. Texte 
        --text-primary-color-dark-max: hsl(0deg, 0%, 0%);    // Dark +
        --text-primary-color-dark: hsl(0deg, 0%, 10%);       // Dark
        --text-primary-color-dark-min: hsl(0deg, 0%, 15%);   // Dark -
        --text-primary-color: hsl(0deg, 0%, 20%);            // Normal
        --text-primary-color-light-min: hsl(0deg, 0%, 40%);  // Clair -
        --text-primary-color-light: hsl(0deg, 0%, 50%);      // Clair 
        --text-primary-color-light-max: hsl(0deg, 0%, 65%);  // Clair +

        // 7. Autres
        --violet-color-25: #FCFAFF;
        --violet-color-50: #F9F5FF;
        --violet-color-100: #F4EBFF;
        --violet-color-200: #E9D7FE;
        --violet-color-300: #D6BBFB;
        --violet-color-400: #B692F6;
        --violet-color-500: #9E77ED;
        --violet-color-600: #7F56D9;
        --violet-color-700: #6941C6;
        --violet-color-800: #53389E;
        --violet-color-900: #42307D;


    /*
     * B. Scrollbars
     */
    --scrollbar-bg-color: #d1d1d1;
    --scrollbar-thumb-bg-color: #888888;
    --scrollbar-thumb-bg-color-hover: #707070;
    --scrollbar-size: 6px;

    /*
     * C. Structure 
     */
    --header-height: 70px;  
    --global-navbar-width: 70px;
    --secondary-navbar-width: 200px;
    
    /*
     * D. Espacements
     */
    --interspace-xxs: 5px;
    --interspace-xs: 10px;
    --interspace-s: 20px;
    --interspace: 30px;
    --interspace-l: 50px;
    --interspace-xl: 60px;
    --interspace-xxl: 80px;

    /*
     * E. Panel
     */
    --panel-header-bg: var(--gray-100);
    --panel-header-title-color: 'black';
    --panel-footer-border: var(--gray-300);
    --panel-addplayer-img-border: var(--primary-color-600);
    
    /*
     * F. PlayerList
     */
    --playerlist-icon-color: var(--light-color);
    --playerlist-icon-bg: var(--primary-color-600);

  }