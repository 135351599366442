h1 {
    font-size: 30px;
    line-height: 38px;
    font-weight: 600;
    color: var(--gray-900);
}

h2 {
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    margin: 0px;
}

h3 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin: 0px;
}

.sub-text {

    color: var(--gray-500);
    font-size: 16px;
    line-height: 24px;

    &-s {

        color: var(--gray-500);
        font-size: 14px;
        line-height: 24px;
    }
}