@mixin panel-on-show-animation($width: 600px){
  @keyframes onShow {
      0% {
          transform: translateX(#{$width});
      }
      100% {
          transform: translateX(0px);
      }
  }
  animation: onShow 400ms forwards;
  width: #{$width};
}
@mixin panel-on-close-animation($width: 600px){
  @keyframes onClose {
      0% {
          transform: translateX(0px);
      }
      100% {
          transform: translateX(#{$width});
      }
  }
  animation: onClose 400ms forwards;
  width: #{$width};
}

// RIGHT PANEL COMPONENT
.panel-container{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #00000099;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .click-outside{
      height: 100vh;
      width: 100vw;
      position: absolute;
  }

  .panel{
      background-color: white;
      height: 100vh;
      max-height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      &.w-600{
          @include panel-on-show-animation();
      }
      &.w-700{
          @include panel-on-show-animation(700px);
      }
      &.w-1200{
          @include panel-on-show-animation(1200px);
      }
      &.w-900{
          @include panel-on-show-animation(900px);
      }
  }

  .panel-reverse{
      background-color: white;
      height: 100vh;
      max-height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      &.w-600{
          @include panel-on-close-animation();
      }
      &.w-700{
          @include panel-on-close-animation(700px);
      }
      &.w-1200{
          @include panel-on-close-animation(1200px);
      }
      &.w-900{
          @include panel-on-close-animation(900px);
      }
  }

  .title-section{
      padding: 31px 37px 31px 24px;
      background-color: var(--panel-header-bg);
      display: flex;
      align-items: center;
      justify-content: space-between;
  }

  .icon-close{
    cursor: pointer;
  }
}


// END OF RIGHT PANEL COMPONENT

// .panel-content {
//   display: flex;
//   flex: 1;
//   flex-direction: column;
//   justify-content: space-between;
// }

// .panel-form {
//   display: flex;
//   align-items: center;
//   flex-direction: column;
//   flex: 1;
//   box-sizing: border-box;
//   padding: 20px 40px 0px 40px;
// }

// .panel-buttons {
//   flex-direction: row;
//   align-items: center;
//   justify-content: flex-end;
//   display: flex;
//   gap: 20px;
//   position: sticky;
//   bottom: 0px;
//   width: 100%;
//   padding: 32px 40px 32px;
//   box-sizing: border-box;
//   border-top: 1px solid var(--form-panel-buttons-border-color);
//   background-color: var(--form-panel-buttons-bg-color);
// }